import React from 'react';
import apiFetch from '../../../scripts/api';

const ExcusedUsers = (props) => {

  const handleDeny = async (email) => {
    await apiFetch(`/api/auth/organizations/exemption/semester?email=${encodeURIComponent(email)}`, "DELETE"); 
    props.setOpen(false);
    props.Refresh();
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {props.semester && (
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Approval
                    </th>
                  )}
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Exemption Reason
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {props.ExemptionRecords.map((person) => (
                  <tr key={person.Name}>
                    {props.semester && (
                      <td className="items-center px-3 py-4">
                        <button 
                          className="inline-flex justify-center rounded-md border border-red-600 px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          onClick={() => handleDeny(person.Email)}
                        >
                          Deny
                        </button>
                      </td>
                    )}
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {person.Name}
                    </td>
                    <td className="whitespace-normal break-words px-3 py-4 text-sm text-gray-500">
                      {person.ExemptionReason}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExcusedUsers;
