import React from 'react';

const YearSelector = ({Change, includeAll}) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, index) => currentYear - index);
  
  return (
    <select
      onChange={(e) => Change(e.target.value)}
      required
      defaultValue=""
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
    >
      <option value="" disabled>Select</option>
        {includeAll && 
          <option key={'All'} value={'All'}>
            All
          </option>
        }
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
    </select>
    );
}

export default YearSelector;
