import { getAuth, getIdToken } from 'firebase/auth';

const BASE_URL = 'https://api.mychaptercheck.com';

async function apiFetch(endpoint, method = 'GET', body = null, headers = {}) {

  //get auth and token for fetch
  let auth = await getAuth();
  while (!auth.currentUser) {
    await new Promise(resolve => setTimeout(resolve, 500)); 
  }
  let token = await getIdToken(auth.currentUser);
  
  //set headers
  headers['Content-Type'] = 'application/json';
  headers['Authorization'] = token;

  //construct config
  const config = {
    method: method,
    headers: new Headers(headers),
    body: body ? JSON.stringify(body) : null,
  };

  //make the api call
  const response = await fetch(`${BASE_URL}${endpoint}`, config);
  const data = await response.json();
  
  //catch and return  
  if (!response.ok) {
    throw new Error(data.message || 'Something went wrong');
  }
  return data;
}

export default apiFetch;
