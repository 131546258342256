import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../firebase-config';

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [actionCode, setActionCode] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (code) {
      verifyPasswordResetCode(auth, code)
        .then(() => {
          setActionCode(code);
        })
        .catch((error) => {
          setError('Invalid or expired password reset link.');
        });
    } else {
      setError('No action code provided.');
    }
  }, [searchParams]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (actionCode) {
      confirmPasswordReset(auth, actionCode, newPassword)
        .then(() => {
          setSuccess('Your password has been reset successfully.');
        })
        .catch((error) => {
          setError('Error resetting password. Please try again.');
        });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6 bg-cover bg-center" style={{ backgroundImage: "url('./images/Skies.jpg')" }}>
      <div className="max-w-md w-full bg-white p-8 rounded-md shadow-md">
        <img
            src="./images/Logos/InfinityLogo.png"
            alt="Logo"
            className="h-20 mx-auto mb-4"
        />
        <h2 className="text-xl font-semibold text-center mb-6">Reset Your Chapter Check Password</h2>
        {error && <p className="text-red-600 mb-4 text-center">{error}</p>}
        {success ? (
          <p className="text-green-600 mb-4 text-center">{success}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter new password"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Confirm new password"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
              Reset Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
