import { useState, useEffect } from 'react';
import LoadingComponent from '../components/Alerts/LoadingComponent';
import apiFetch from '../scripts/api';
const MemberDashboard = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    apiFetch('/api/public/healthcheck');

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingComponent />; 
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-white to-gray-500">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 text-center">
        <img
          src="./images/Logos/InfinityLogo.png"
          alt="Logo"
          className="h-16 mx-auto mb-4"
        />
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Welcome, {props.user.DisplayName}!
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          We are excited to announce our brand new mobile application!
        </p>
        <p className="text-gray-600">
          Please note that the member web app has been discontinued. All features and services have been moved to the new app for a better experience.
        </p>
        <div className="mt-6">
          <a
            href="https://testflight.apple.com/join/gr6s6Dd4"
            className="inline-block bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700"
          >
            Download the New App
          </a>
          <button
          onClick={() => props.removeAppUser()}
          className="absolute top-4 right-4 bg-gray-600 text-white font-semibold py-1 px-3 rounded-md text-sm hover:bg-blue-700"
        >
          Logout
        </button>
        </div>
      </div>
    </div>
  )
}

export default MemberDashboard;
