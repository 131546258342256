import YearSelector from '../../Misc/YearSelect'
import { useState } from "react";
import WarningMessage from '../../Alerts/WarningMessage';
import apiFetch from '../../../scripts/api';
import { exportToCSV } from '../../../scripts/Utility';
import SemesterSelector from '../../Misc/SemesterSelect';

const MembersReportsForm = (props) => {
  const [pledgeClass, setPledgeClass] = useState('');
  const [semester, setSemester] = useState('');
  const [memberStatus, setMemberStatus] = useState('all');
  const [exportOption, setExportOption] = useState('csv');
  const [warningMessage, setWarningMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    let limits = props.OrganizationContext.UnexcusedLimit;
    let result = await generateMembersReport(limits);
    if(!result){
      setWarningMessage("No members to report.")
    }
  }

  const generateMembersReport = async (attendanceLimit) => {
    try {
        const endpoint = `/api/auth/organizations/reports/members`;
        const body = { pledgeClass, memberStatus, exportOption, attendanceLimit, semester};
        const result = await apiFetch(endpoint, "POST", body);
        exportToCSV(result.data);
        console.log('Members report generated:', result.data);
        return result.data;
    } catch (error) {
        console.error('Error generating members report:', error);
        throw error;
    }
};


    return (
      <div className="space-y-10 divide-y divide-gray-900/10 pb-10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Generate Members Report</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Configure the options below to generate attendance reports for your organization.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                      <label htmlFor="pledge-class-selection" className="block text-sm font-medium leading-6 text-gray-900">
                          Select Pledge Class 
                      </label>
                      <div className="mt-2">
                          <YearSelector Change={setPledgeClass} includeAll={false}/>
                      </div>
                  </div>
                  <div className="sm:col-span-3">
                      <label htmlFor="pledge-class-selection" className="block text-sm font-medium leading-6 text-gray-900">
                          Select Semester
                      </label>
                      <div className="mt-2">
                        <SemesterSelector Change={setSemester}></SemesterSelector>
                      </div>
                  </div>
                  <div className="sm:col-span-6">
                      <label htmlFor="member-status" className="block text-sm font-medium leading-6 text-gray-900">
                          Member Status
                      </label>
                      <div className="mt-2">
                          <select
                          id="member-status"
                          name="member-status"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          onChange={(e) => setMemberStatus(e.target.value)}
                          >
                          <option value="all">All</option>
                          <option value="attendance">Top Attendance</option>
                          <option value="exempt">Exemption Limit's</option>
                          <option value="missing">Unexcused Limit's</option>
                          </select>
                      </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor="export-options" className="block text-sm font-medium leading-6 text-gray-900">
                      Export Options
                    </label>
                    <div className="mt-2 grid grid-cols-2 gap-4">
                    <select
                        id="export-options"
                        name="export-options"
                        onChange={(e) => setExportOption(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                        <option value="csv">Excel</option>
                        <option value="pdf" disabled>PDF</option>
                    </select>
                    <button
                        type="submit"
                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 w-full sm:w-auto"
                    >
                        Generate Report
                    </button>
                    </div>
                  </div>
                </div>
              </div>
              {warningMessage &&
                <WarningMessage WarningMessage={warningMessage}></WarningMessage>
              }              
              </form>
          </div>
      </div>
    );
  }
  
  export default  MembersReportsForm;