import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {Bars3Icon, BuildingOfficeIcon,CalendarIcon, Cog6ToothIcon,DocumentCheckIcon,HomeIcon,MapIcon,UserGroupIcon, XMarkIcon,} from '@heroicons/react/24/outline'
import { ArrowPathIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import MembersPage from '../components/Moderator/Members/MembersPage.js'
import Calendar from '../components/Moderator/Calendar/Calendar.js'
import Organization from '../components/Moderator/Organization/Organization.js'
import Dashboard from '../components/Moderator/Dashboard/Dashboard.js'
import Locations from '../components/Moderator/Locations/Locations.js';
import UserSettings from '../components/Moderator/UserSettings.js';
import Reports from '../components/Moderator/Reports/Reports.js';
import apiFetch from '../scripts/api.js'; 

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ModeratorDashboard(props) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [tabSelected, setTabSelected] = useState("Dashboard");
    const [OrganizationContext, setOrganizationContext] = useState(null)
    const [isMounted, setIsMounted] = useState(true);
    const [useEffectTrigger, setUseEffectTrigger] = useState(true);
    const [Moderators, setModerators] = useState(null);
    const [Members, setMembers] = useState(null);
    const [navigation, setNavigation] = useState([
      { name: 'Dashboard', href: '#', current: true, icon: HomeIcon },
      { name: 'Calendar', href: '#', current: false, icon: CalendarIcon },
      { name: 'Members', href:'#', current: false, icon: UserGroupIcon},
      { name: 'Reports', href:'#', current: false, icon: DocumentCheckIcon},
      { name: 'Locations', href:'#', current: false, icon: MapIcon},
      { name: 'Organization', href: '#', current: false, icon: BuildingOfficeIcon },
  ]);
  const userNavigation = [
    { name: 'Your profile', OnClick: '' },
    { name: 'Sign out', OnClick: '#' },
  ]

    useEffect(() => {
      if (isMounted) {
          const fetchData = async () => {
              try {
                  // Get Organization ID by Name
                  const Org = await apiFetch(`/api/auth/organization`);
                  setOrganizationContext(Org.data);

                  // Process Events and Members
                  await apiFetch(`/api/auth/organizations/events/process-all`, 'POST');

                  // Fetch Organization Members
                  const members = await apiFetch(`/api/auth/organization/members`);
                  setMembers(members.data);

                  // Fetch Moderators
                  const moderatorIds = Org.data.Moderators || [];
                  const moderatorPromises = moderatorIds.map(id => 
                    apiFetch(`/api/public/userprofile/${id}`)
                      .then(response => response.data)
                      .catch(() => "None")
                  );                  
                  const moderatorsArray = await Promise.all(moderatorPromises);
                  setModerators(moderatorsArray.filter(user => user !== "None").map((user, index) => ({
                      name: user.DisplayName,
                      UID: moderatorIds[index]
                  })));
              } catch (error) {
                  console.error('Error fetching data:', error);
              } finally {
                  setIsMounted(false);
              }
          };
          fetchData();
      }

      // Cleanup function
      return () => setIsMounted(false);
  }, [useEffectTrigger, props.user.Organization, isMounted]);

    const RefreshSite = async () => {
        setIsMounted(true);
        setUseEffectTrigger(prev => !prev);
    }

    const tabSelect = (name) => {
    const updatedNavigation = navigation.map((item) => ({
        ...item,
        current: item.name === name,
        }));

        setNavigation(updatedNavigation);
        setTabSelected(name);
    }

    const handleProfileDropdown = (action) =>{
        if(action == 'Your profile'){
          tabSelect('Settings')
        }
        else if(action == 'Sign out'){
          props.removeAppUser()
        }
    }
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>
            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component*/}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="./images/Logos/InfinityLogo.png"
                        alt="Chapter Check"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                    onClick={() => tabSelect(item.name)}
                                    className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <a 
                            onClick={() => tabSelect('Settings')}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          >
                            <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            Settings
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component */}
          <div style={{ backgroundColor: '#333' }} className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center justify-center">
            <img
              className="h-32 w-auto"
              src="./images/Logos/InfinityLogo.png"
              alt="Chapter Check"
            />
          </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <div className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-white" style={{ backgroundColor: '#C1C0C8' }}>
                    <span className="h-6 w-6 shrink-0"></span> 
                     {OrganizationContext && <p className='text-center'>{OrganizationContext.Name}</p>}
                  </div>
                </li>
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                            onClick={() => tabSelect(item.name)}
                            style={{
                              color: item.current ? '#FAFAFC' : '#C1C0C8',
                              backgroundColor: item.current ? '#C1C0C8' : 'transparent',
                            }}
                            className={classNames(
                            item.current
                              ? ' text-white'
                              : 'text-gray-400 hover:text-white hover:bg-blue-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a onClick={() => tabSelect('Settings')}  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                    <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1">
                <div className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"/>
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
              <button
                  type="button"
                  className="text-gray-700 hover:text-gray-900 inline-flex"
                  onClick={() => RefreshSite()}>
                  <svg className="h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <ArrowPathIcon cx={1} cy={1} r={1} />
                  </svg>
                  Refresh Data
              </button>
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="./images/Logos/InfinityLogo.png"
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {props.user.DisplayName}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => handleProfileDropdown(item.name)}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className={classNames('py-10', 'min-h-screen', (tabSelected) ? 'bg-gray-50' : '')}>
            <div className="px-4 sm:px-6 lg:px-8 ">
                { tabSelected === 'Dashboard' && <Dashboard user={props.user} Update={useEffectTrigger} OrganizationContext={OrganizationContext}/>}
                { tabSelected === 'Calendar' && <Calendar user={props.user} />}
                { tabSelected === 'Members' && <MembersPage Members={Members} OrganizationContext={OrganizationContext} Refresh={RefreshSite}/>}
                { tabSelected === 'Reports' && <Reports OrganizationContext={OrganizationContext}/>}
                { tabSelected === 'Locations' && <Locations/>}
                { tabSelected === 'Organization' && <Organization OrganizationContext={OrganizationContext} Moderators={Moderators} Members={Members} Refresh={RefreshSite}/>}
                { tabSelected === 'Settings' && <UserSettings user={props.user}/>}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
