import React from 'react';
import WarningMessage from './WarningMessage';

const LoadingComponent = (props) => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div
        className="mb-4 inline-block h-32 w-32 animate-spin rounded-full border-8 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >
        </span>
      </div>
      {props.Processing &&
        <div className='pt-5'>
          <WarningMessage WarningMessage={"Please do not refresh the page."}/>
        </div>
      }
    </div>
  );
};

export default LoadingComponent;
