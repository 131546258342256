import { useState, useEffect } from 'react';
import DataBar from './dataBar.js';
import PastEvents from "./pastEvents.js";
import LoadingComponent from '../../Alerts/LoadingComponent.js';
import apiFetch from '../../../scripts/api.js';
import LiveEvents from './LiveEvents.js';

const Dashboard = ({Update, OrganizationContext, user}) => {
    const [stats, setStats] = useState(null);
    const [pastEvents, setPastEvents] = useState(null);
    const [liveEvents, setLiveEvents] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {
        const fetchInformation = async () => {
            setIsLoading(true); 
            try {
                const localPastEvents = await apiFetch('/api/auth/organizations/past-events'); //todo change route
                setPastEvents(localPastEvents.data);
                const localLiveEvents = await apiFetch('/api/auth/organizations/moderator/active-events');
                setLiveEvents(localLiveEvents.data);
                console.log(localLiveEvents);
                const Statistics = await apiFetch("/api/auth/organizations/statistics");
                setStats(Statistics.data);
            } catch (error) {
                console.error("Failed to fetch locations or read", error);
            } finally {
                setIsLoading(false); 
            }
        };
  
        fetchInformation();
    }, [Update]);
  
    if (isLoading) {
        return <LoadingComponent Processing={isLoading}/>; 
    }
  
    return (
        <div>
            {stats && <DataBar Stats={stats} />}
            {liveEvents && liveEvents.length > 0 && <LiveEvents Events={liveEvents} user={user}/>}
            {pastEvents && <PastEvents Events={pastEvents} user={user} OrganizationContext={OrganizationContext} />}
        </div>
    );
};

export default Dashboard;
