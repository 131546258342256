import { useState, useEffect } from 'react';
import DeleteUserModal from '../Misc/DeleteUserModal';
import { auth } from '../../firebase-config';
import { sendPasswordResetEmail } from 'firebase/auth';
import SuccessMessageAlert from '../Alerts/SuccessMessageAlert';
import apiFetch from '../../scripts/api';
import LoadingComponent from '../Alerts/LoadingComponent';

const UserSettings = (props) => {
  const [open, setOpen] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState('');
  const [SettingsInfo, setSettingsInfo] = useState();

  const handleSendEmail = () => {
    window.location.href = "mailto:landenfogle@gmail.com?subject=Chapter Check Feedback &body=Feedback/Suggestions:";
  };

  const handlePasswordReset = async () => {
    await sendPasswordResetEmail(auth, props.user.Email)
    .then(() => {
      setSuccessMessage(`Password reset link sent to ${props.user.Email}.`);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
     })
     .catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const userSettings = await apiFetch(`/api/auth/individual/settings`, 'GET');
        setSettingsInfo(userSettings.data);
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
    };
  
    fetchSettings();
  }, []); 

  if(!SettingsInfo){
    return <LoadingComponent/>
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 pt-5 flex flex-wrap items-end justify-between sm:flex-nowrap">
          <DeleteUserModal open={open} setOpen={setOpen}/>
          {SuccessMessage && <SuccessMessageAlert SuccessMessage={SuccessMessage}/> }    
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-12 w-12 rounded-full"
                src={SettingsInfo.ProfileImageUrl ? SettingsInfo.ProfileImageUrl : './images/Logos/InfinityLogo.png'}
                alt=""
              />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">{SettingsInfo.DisplayName}</h3>
              <p className="text-sm text-gray-500">
                <a href="#">@{SettingsInfo.Organization}</a>
              </p>
            </div>
          </div>
          <div className="ml-4 flex flex-shrink-0">
          </div>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Profile</h2>
          <div className="mt-4">
            <div className="text-sm font-medium text-gray-500">Full name</div>
            <div className="text-lg text-gray-900 mt-1">{SettingsInfo.DisplayName}</div>
          </div>
          <div className="mt-4">
            <div className="text-sm font-medium text-gray-500">Email address</div>
            <div className="text-lg text-gray-900 mt-1">{SettingsInfo.Email}</div>
          </div>
          <div className="mt-4">
            <div className="text-sm font-medium text-gray-500">Organization Role</div>
            <div className="text-lg text-gray-900 mt-1">{SettingsInfo.Role}</div>
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Actions</h2>
          <div className="mt-4">
            <button onClick={() => handleSendEmail()} type="button" className="rounded-md bg-gray-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400">
              Submit Feeback Email
            </button>
          </div>
          <div className="mt-4">
            <button onClick={() => handlePasswordReset()} type="button" className="rounded-md bg-gray-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400">
              Send Password Reset Email
            </button>
          </div>
          <div className="mt-4">
            <button onClick={() => setOpen(true)} type="button" className="rounded-md bg-red-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700">
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
