import { useState } from 'react';
import EventModal from './EventModal';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import EventFormModal from '../Calendar/EventFormModal.js';


const PastEvents = (props) => {
  const [open, setOpen] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const handleAdd = () => {}

  return (
    <div>
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 pt-5">Recent Events:</h3>
        <EventFormModal open={openAdd} setOpen={setOpenAdd} CRUD="CREATE" Organization={props.OrganizationContext.Name} Refresh={handleAdd}></EventFormModal>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <button 
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setOpenAdd(true)}
          >
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Create a new Event</span>
          </button>
          {props.Events.map((event) => ( //splice in the future
            <div key={event.title} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 flex">
              <div className="flex-1">
                  <button onClick={() => setOpen(event.id)} className="w-full text-left focus:outline-none">
                      <dl className="p-4">
                        <dt className="truncate text-md md:text-xl lg:text-2xl font-medium text-gray-500">{event.EventName}</dt>
                        <dd className="mt-1 text-lg md:text-xl lg:text-2xl font-semibold tracking-tight text-gray-900"></dd>
                        <div className="mt-1 flex items-center gap-x-2 text-xs md:text-sm leading-5 text-gray-500">
                            <p>{new Date(event.StartDate.seconds * 1000).toLocaleDateString("en-US", {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>                        
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs md:text-sm leading-5 text-gray-500">
                            <p> {event.Moderator}</p>
                        </div>
                      </dl>
                      <div className="px-4 py-3 flex justify-between items-end text-xs md:text-sm lg:text-base flex-wrap">
                          <div className="text-center space-y-1 md:space-y-2 flex-1 min-w-0">
                            <p className="text-lg md:text-2xl text-gray-800">
                              {event.PercentAttendance != null ? event.PercentAttendance.toFixed(1) : 'Processing'}%
                            </p>
                            <p className="text-sm md:text-md lg:text-lg text-stone-300 font-medium font-sans overflow-hidden whitespace-nowrap">Attendance</p>
                          </div>
                          <div className="text-center space-y-1 md:space-y-2 flex-1 min-w-0">
                            <div className="flex flex-col items-center justify-center">
                              {event.PercentAttendance >= props.OrganizationContext.Quorum ?
                                <CheckCircleIcon className="w-10 h-10 text-green-700" /> :
                                <XCircleIcon className="w-10 h-10 text-gray-500" />
                              } 
                              <p className="text-sm md:text-md lg:text-lg text-stone-300 font-medium font-sans overflow-hidden whitespace-nowrap">
                                  Quorum
                              </p>
                            </div>
                          </div>
                          <div className="text-center space-y-1 md:space-y-2 flex-1 min-w-0">
                              <p className="text-lg md:text-2xl text-gray-800">{event.MissingMembers}</p>
                              <p className="text-sm md:text-md lg:text-lg text-stone-300 font-medium font-sans overflow-hidden whitespace-nowrap">Absent</p>
                          </div>
                      </div>
                  </button>
              </div>
              <div>
                {open == event.id &&
                  <EventModal open={open} setOpen={setOpen} event={event} Organization={props.user.Organization}></EventModal>
                }
              </div>
            </div>
            ))}
        </dl>
      </div>
  )
}

export default PastEvents;