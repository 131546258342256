import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ConfirmAlert from '../../Alerts/ConfirmAlert';
import apiFetch from '../../../scripts/api';

const EditEventForm = ({ eventToEdit, modal, Organization, Refresh, Locations }) => {
  const [EventName, setEventName] = useState('');
  const [EventLocation, setEventLocation] = useState('');
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [Moderator, setSelectedModerator] = useState('');
  const [chapterMeeting, setChapterMeeting] = useState(false);
  const [MultipleCheckinsEnabled, setMultipleCheckinsEnabled] = useState(false);
  const [IncludeExemptionForm, setIncludeExemptionForm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [PollQuestion, setPollQuestion] = useState();
  const [EventType, setEventType] = useState();
  const [AssignedPC, setAssignedPC] = useState();

  useEffect(() => {
    const fetchEvent = async () => {
      const event = await apiFetch(`/api/auth/organizations/events/${eventToEdit}`);
      if (event.success) {
        let data = event.data;
        setEventName(data.EventName);
        setEventLocation(data.EventLocation);
        setStartDate(new Date(data.StartDate.seconds ? data.StartDate.seconds * 1000 : data.StartDate));
        setEndDate(new Date(data.EndDate.seconds ? data.EndDate.seconds * 1000 : data.EndDate));
        setSelectedModerator(data.Moderator);
        setChapterMeeting(data.chapterMeeting);
        setMultipleCheckinsEnabled(data.MultipleCheckinsEnabled);
        setIncludeExemptionForm(data.IncludeExemptionForm);
        setPollQuestion(data.PollQuestion);
        setEventType(data.EventType);
        setAssignedPC(data.AssignedPC);
      }
    };

    if (eventToEdit) {
      fetchEvent();
    }
  }, [eventToEdit]);

  const dateToCustomFormat = (date) => {
    if (!date) return { _seconds: 0, _nanoseconds: 0 }; 
  
    const milliseconds = date instanceof Date ? date.getTime() : new Date(date).getTime();
    return {
      seconds: Math.floor(milliseconds / 1000),
      nanoseconds: (milliseconds % 1000) * 1000000
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedEventData = {
      EventName,
      EventLocation,
      StartDate: dateToCustomFormat(StartDate),
      EndDate: dateToCustomFormat(EndDate),
      Moderator,
      chapterMeeting,
      MultipleCheckinsEnabled,
      IncludeExemptionForm,
      AssignedPC: (EventType === 'StudyHours') ? AssignedPC : undefined,
      IncludeExemptionForm: (EventType === 'Chapters') ? IncludeExemptionForm : undefined,
      PollQuestion: (EventType === 'Chapters' || EventType === 'StudyHours') ? PollQuestion : undefined
    };
    try {
      const result = await apiFetch(`/api/auth/organizations/events/${eventToEdit}`, 'PATCH', updatedEventData);
      console.log('Event updated successfully:', result.data);
      modal(false);
      Refresh();
      return result.data;
    } catch (error) {
        console.error('Error updating event:', error);
    }
  };

  const handleDelete = async () => {
      const result = await apiFetch(`/api/auth/organizations/events/${eventToEdit}`, 'DELETE');
      if (result.success) {
        modal(false);
        Refresh();
      }
  };

  useEffect(() => {
    let message = '';
    if (EndDate && EndDate < new Date()) {
      message = 'Cannot save as the event end date has already passed.';
    } 
    else if (StartDate && EndDate && StartDate >= EndDate) {
      message = 'Start date must be before the end date.';
    }
    setValidationMessage(message);
  }, [StartDate, EndDate]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <ConfirmAlert confirm={handleDelete} Title={"Delete Event"} Message={"Confirm Delete"} open={openConfirm} setOpen={setOpenConfirm}></ConfirmAlert>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Edit Event Info</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Change the event details if neeeded. 
            </p>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label htmlFor="event-name" className="block text-sm font-medium leading-6 text-gray-900">
                Event name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="event-name"
                  id="event-name"
                  autoComplete="event-name"
                  value={EventName}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setEventName(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                Event Location
              </label>
              <div className="mt-2">
                <select
                  id="event-location"
                  name="event-location"
                  autoComplete="event-location"
                  value={EventLocation}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={(e) => setEventLocation(e.target.value)}
                >
                 <option value="">--Select--</option>
                {Locations.map((location, index) => (
                    <option key={index} value={location.Name}>{location.Name}</option> 
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="event-date" className="block text-sm font-medium leading-6 text-gray-900">
                Event Start Time/Date
              </label>
              <div className="mt-2">
                <DatePicker
                  selected={StartDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText='mm/dd/yyyy hh:mm'
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
               />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="event-date" className="block text-sm font-medium leading-6 text-gray-900">
                Event End Time/Date
              </label>
              <div className="mt-2">
              <DatePicker
                selected={EndDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholderText='mm/dd/yyyy hh:mm'
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="moderator" className="block text-sm font-medium leading-6 text-gray-900">
                Select Moderator
              </label>
              <div className="mt-2">
                <select
                  id="select-moderator"
                  name="select-moderator"
                  autoComplete="selecte moderator"
                  disabled
                  onChange={(e) => setSelectedModerator(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={Moderator}>{Moderator}</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="event-type" className="block text-sm font-medium text-gray-900">
                Event Type
              </label>
              <select
                id="event-type"
                name="event-type"
                value={EventType}
                onChange={(e) => setEventType(e.target.value)}
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                disabled
              >
                <option value="">--Select Type--</option>
                <option value="Chapters">Chapter</option>
                <option value="Philanthropy">Philanthropy</option>
                <option value="StudyHours">Study Hours</option>
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Configurations</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Select Config Options
            </p>
          </div>
          <div className="max-w-2xl space-y-10 md:col-span-2">
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">Select</legend>
              <div className="mt-6 space-y-6">
              {(EventType === 'Chapters' || EventType === 'StudyHours') && (
                  <div className="sm:col-span-6">
                    <label htmlFor="poll-question" className="block text-sm font-medium text-gray-900">
                      Poll Question
                    </label>
                    <input
                      type="text"
                      id="poll-question"
                      name="poll-question"
                      value={PollQuestion}
                      onChange={(e) => setPollQuestion(e.target.value)}
                      placeholder="Enter a polling question..."
                      className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                )}
                {EventType === 'Chapters' && (
                  <div className="sm:col-span-6">
                    <label htmlFor="exemption-form" className="flex items-center">
                      <input
                        type="checkbox"
                        id="exemption-form"
                        name="exemption-form"
                        checked={IncludeExemptionForm}
                        onChange={(e) => setIncludeExemptionForm(e.target.checked)}
                        className="mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      Include Exemption Form
                    </label>
                  </div>
                )}
             {EventType === 'StudyHours' && (
              <div>
                <div className="sm:col-span-6">
                  <label htmlFor="pc-assignment" className="block text-sm font-medium text-gray-900">
                    Assign Pledge Class
                  </label>
                  <select
                    id="pc-assignment"
                    className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={AssignedPC} 
                    disabled
                  >
                    <option value={AssignedPC}>{AssignedPC}</option>
                  </select>
                </div>
                <div className="sm:col-span-6 pt-6">
                    <label htmlFor="multi-checkins" className="block text-sm font-medium text-gray-900">
                      <input
                        type="checkbox"
                        id="muti-checkins"
                        name="muti-checkins"
                        checked={MultipleCheckinsEnabled}
                        onChange={(e) => setMultipleCheckinsEnabled(e.target.checked)}
                        className="mr-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      Enable Multiple Checkins
                    </label>
                  </div>
                </div>
              )}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
      {validationMessage && (
          <p className="text-sm text-red-500">{validationMessage}</p>
        )}
        <button
          type="submit"
          disabled={!!validationMessage} 
          className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
            validationMessage ? 'bg-gray-500 text-gray-100 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-500'
          } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
        >
          Save
        </button>
        <button
          type="button"
          onClick={() => setOpenConfirm(true)}
          className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Delete
        </button>
      </div>
    </form>
  )
}

export default EditEventForm;
