import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition, DialogPanel, DialogTitle, TransitionChild } from '@headlessui/react';
import ExcusedUsers from '../Dashboard/ExcusedUsers';
import LoadingComponent from '../../Alerts/LoadingComponent';
import apiFetch from '../../../scripts/api';

const SemesterExemptionModal = ({ Open, setOpen, Refresh }) => {
  const cancelButtonRef = useRef(null);
  const [exemptions, setExemptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(null);

  useEffect(() => {
    const fetchExemptions = async () => {
      try {
        const semesterExemptions = await apiFetch(`/api/auth/organizations/exemption/semester`);
        setExemptions(semesterExemptions.data.exemptionRecords || []);
        setIsAccepting(semesterExemptions.data.IsAcceptingExemptions || false);
      } catch (error) {
        console.error('Error fetching exemptions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchExemptions();
  }, [Open]);

  const handleToggle = async () => {
    try {
      const newIsAccepting = !isAccepting;
      setIsAccepting(newIsAccepting);
      await apiFetch('/api/auth/organization/exemption/semester/toggle', 'POST', { IsAccepting: newIsAccepting });
    } catch (error) {
      console.error('Error updating exemption status:', error);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Transition appear show={Open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setOpen(false)}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex justify-between items-center mb-4">
                  <DialogTitle as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                    Semester Exemption Records
                  </DialogTitle>
                  <button
                    type="button"
                    className={`px-4 py-2 text-sm font-medium text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${isAccepting ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500' : 'bg-red-600 hover:bg-red-700 focus:ring-red-500'}`}
                    onClick={handleToggle}
                  >
                    {isAccepting ? 'Accepting Exemptions' : 'Enable Exemptions'}
                  </button>
                </div>
                <div className="mt-4">
                  {exemptions.length > 0 ? (
                    <ExcusedUsers ExemptionRecords={exemptions} semester={true} setOpen={setOpen} Refresh={Refresh}/>
                  ) : (
                    <p className="text-sm text-gray-500">No exemptions to display</p>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SemesterExemptionModal;
