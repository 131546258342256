//  Generates a CSV file from the data and triggers a download in the browser.
//  @param {Array} data - Array of objects to be exported as CSV.
export const exportToCSV = (data) => {
 if (data.length > 0) {
   const firstKey = Object.keys(data[0])[0]; 
 
   data.sort((a, b) => {
     if (a[firstKey] && b[firstKey]) {
       return a[firstKey].toString().localeCompare(b[firstKey].toString());
     } else {
       return 0; 
     }
   });
 }else {
   console.error("No data to export");
   return false;
 }

 const DateString = new Date().toISOString().split('T')[0];
 const csvRows = [];
 const headers = Object.keys(data[0]);
 csvRows.push(headers.join(','));
 data.forEach(row => {
   const values = headers.map(header => {
     const escaped = (''+row[header]).replace(/"/g, '\\"');
     return `"${escaped}"`;
   });
   csvRows.push(values.join(','));
 });
 let csvData = csvRows.join('\n');
 const blob = new Blob([csvData], { type: 'text/csv' });
 const url = window.URL.createObjectURL(blob);
 const a = document.createElement('a');
 a.setAttribute('hidden', '');
 a.setAttribute('href', url);
 a.setAttribute('download', `ChapterCheckExport-${DateString}.csv`);
 document.body.appendChild(a);
 a.click();
 document.body.removeChild(a);
 return true;
};



