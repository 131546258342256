import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import YearSelector from './YearSelect'
import apiFetch from '../../scripts/api'

const AddUserOrganization = (props) => {
  const [joinCode, setJoinCode] = useState('')
  const [displayMessage, setDisplayMessage] = useState(null);
  const [success, setSuccess] = useState(false)
  const [selectedPC, setSelectedPC] = useState(null);

  const handleSubmit = async () =>{
    if (joinCode === '' || selectedPC === null) {
      setDisplayMessage("Fields Empty");
      return;
    } else {
      const storedUser = await localStorage.getItem('user');
      const uid = JSON.parse(storedUser).UID;

      const result = await apiFetch("/api/auth/organizations/assign-user", 'POST', {
          UID: uid,
          joinCode: joinCode,
          pledgeClass: selectedPC
      });
      
      if (result.success) {
          setDisplayMessage("Please re-login to see new organization");
          localStorage.removeItem('user'); 
          setSuccess(true);
      } else {
          setDisplayMessage("Incorrect Join Code");
          setSuccess(false);
      }
    } 
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <ArrowRightOnRectangleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Enter Join Code and Pledge Class
                      <h5 className="text-sm text-gray-500">
                                {displayMessage}
                      </h5>
                    </Dialog.Title>
                    <div className="mt-2">
                        {!success &&
                          <div>
                            <input     
                              placeholder='Code'              
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 "
                              onChange={(e) => setJoinCode(e.target.value)}>
                            </input>
                            <div className='pt-5'>
                              <YearSelector Change={setSelectedPC} includeAll={false}/>
                            </div>  
                            <div className="mt-5 sm:mt-6">
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={() => handleSubmit()}
                              >
                                Join
                              </button>
                            </div>
                          </div>
                        }
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddUserOrganization;