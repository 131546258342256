import Features from '../components/Landing/Features';
import AdminFeatures from '../components/Landing/AdminFeatures';
import Footer from '../components/Landing/Footer';
import {HeadingPage} from '../components/Landing/Heading'
import Testimonials from '../components/Landing/Testimonials';
import Pricing from '../components/Landing/Pricing';
import FrequentlyAskedQuestions from '../components/Landing/FAQS';
import { CallToAction } from '../components/Landing/CallToAction';
import { Header } from '../components/Landing/Header';

const LandingPage = () => {
 
  return (
    <div className="bg-white">
      <Header></Header>
      <HeadingPage/>
      <CallToAction></CallToAction>
      <AdminFeatures/>
      {/* <Testimonials></Testimonials> */}
      {/* <Pricing></Pricing> */}
      <FrequentlyAskedQuestions></FrequentlyAskedQuestions>
      <Footer/>
    </div>
  )
}
export default LandingPage;