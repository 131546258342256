import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react'
import EventMembers from './EventMembers.js'
import '../../../styles/Modals.css';
import apiFetch from '../../../scripts/api.js';

const  EventModal = (props) => {
  const cancelButtonRef = useRef(null)
  const [isMounted, setIsMounted] = useState(true); 
  const [presentMembers, setPresentMembers] = useState(null); 
  const [exemptMembers, setExemptMembers] = useState({});
  const [absentMembers, setAbsentMembers] = useState({});
  const [semesterExemptMembers, setSemesterExemptMembers] = useState({});

  useEffect( () => {
    setIsMounted(true);
    const fetch = async () => {
      if (isMounted) {
        try {
          const ResponseEventData = await apiFetch(`/api/auth/organizations/events/${props.event.id}/details`);
          if (ResponseEventData.success) {
              const data = await ResponseEventData.data;
              setPresentMembers(data.presentMembers);
              setExemptMembers(data.exemptions);
              setAbsentMembers(data.absentMembers);
          } else {
              throw new Error('Failed to fetch event details');
          }
          const semester = await apiFetch(`/api/auth/organizations/exemption/semester`);
          setSemesterExemptMembers(semester.data.exemptionRecords);
         } catch (error) {
          console.error('Error fetching organization:', error);
        } finally {
          setIsMounted(false);
        }
      }
    }
    fetch();
  }, []); 

  if (isMounted) {
    return (<div></div>);
  }

  return (
    <div>
      <Transition show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  max-width-large sm:p-6 md:w-1/2 lg:w-1/2">
                  <div className="scrollbar-hidden overflow-auto max-w-1/2 max-h-[70vh]">
                    <div className=" sm:items-start justify-context-center">
                      <div className="mt-3 text-center sm:ml-5 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Report for {props.event.EventName} 
                        </DialogTitle>
                        {props.event.PollQuestion && (
                            <div className="absolute top-0 right-0 m-3 bg-gray-100 p-2 italic rounded shadow">
                              <p className="text-xs text-gray-700">Question: {props.event.PollQuestion}</p>
                            </div>
                          )}
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                          </p>
                        </div>
                        <EventMembers Present={presentMembers} Absent={absentMembers} Exempt={exemptMembers} SemesterExempt={semesterExemptMembers} Event={props.event}/>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default EventModal;