const faqs = [
  {
    question: 'What is Chapter Check?',
    answer:
      'Chapter Check is an app designed for Greek chapters to manage events, track study hours, take attendance, and keep track of chapter activities.',
  },
  {
    question: 'How can I take attendance for a chapter event?',
    answer:
      'To take attendance, go to the "Calendar" page of the admin dashboard, click add event, and fill out the information. Members can check in on the app once the event has started.',
  },
  {
    question: 'How do I log study hours?',
    answer:
      'You can log study hours by navigating to the "Home" section in the app and clock in and out of a study hour event. Make sure to clock out to accurately record your hours.',
  },

  {
    question: 'What if I can not attend chapter?',
    answer:
      'If you can not attend chapter and exemptions are allowed, you may fill out an exemption form up to 24 hours in advance. Semester exemptions from chapters are additionally available. ',
  },
];

  
  export default function FrequentlyAskedQuestions() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-5">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
              <p className="mt-4 text-base leading-7 text-gray-600">
                Can’t find the answer you’re looking for? Reach out to us!
              </p>
            </div>
            <div className="mt-10 lg:col-span-7 lg:mt-0">
              <dl className="space-y-10">
                {faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    )
  }
  