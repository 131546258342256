import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { Header } from '../components/Landing/Header'

const faqs = [
  {
    question: "Privacy Policy",
    answer: `
      This privacy policy applies to the Chapter Check app (hereby referred to as "Application") for mobile devices that was created by Landen Fogle (hereby referred to as "Service Provider") as a Free service. This service is intended for use "AS IS".

      Information Collection and Use

      The Application collects information when you download and use it. This information may include information such as your device's Internet Protocol address (e.g. IP address), the pages of the Application that you visit, the time and date of your visit, the time spent on those pages, and the operating system you use on your mobile device.

      The Application collects your device's location, which helps the Service Provider determine your approximate geographical location and make use of in the following ways:

      - Geolocation Services: The Service Provider utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.
      - Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider to analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.
      - Third-Party Services: Periodically, the Service Provider may transmit anonymized location data to external services.

      The Service Provider may use the information you provided to contact you to provide important information, notices, and marketing promotions.

      Third Party Access

      Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application. The Service Provider may share your information with third parties in the ways described in this privacy statement.

      Opt-Out Rights

      You can stop all collection of information by uninstalling the Application. 

      Data Retention Policy

      The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter.

      Children

      The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.

      Security

      The Service Provider is concerned about safeguarding the confidentiality of your information and provides physical, electronic, and procedural safeguards.

      Changes

      This Privacy Policy may be updated from time to time. You are advised to consult this Privacy Policy regularly for any changes.
      
      Your Consent

      By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy.

      Contact Us

      If you have any questions regarding privacy while using the Application, please contact the Service Provider at landenfogle@gmail.com.
    `,
  },
  {
    question: "Terms of Use",
    answer: `
      These terms and conditions apply to the Chapter Check app (hereby referred to as "Application") for mobile devices that was created by Landen Fogle (hereby referred to as "Service Provider") as a Free service.

      Upon downloading or utilizing the Application, you agree to the following terms. Unauthorized copying, modification, or extraction of the source code of the Application is strictly prohibited.

      The Application stores and processes personal data that you have provided to the Service Provider in order to provide the Service. The Service Provider advises against jailbreaking or rooting your phone, which could expose your phone to malware and compromise your phone's security features.

      The Application requires an active internet connection. The Service Provider cannot be held responsible if the Application does not function at full capacity due to lack of access to Wi-Fi or if you have exhausted your data allowance.

      If you are using the application outside of a Wi-Fi area, you may incur charges from your mobile provider. By using the application, you accept responsibility for any such charges, including roaming data charges.

      The Service Provider cannot always assume responsibility for your usage of the application. It is your responsibility to ensure your device remains charged.

      The Service Provider may wish to update the application at some point and you agree to always accept updates when offered. The Service Provider may also terminate the use of the Application at any time without prior notice.

      Changes to These Terms and Conditions

      The Service Provider may periodically update their Terms and Conditions. These terms and conditions are effective as of 2024-09-22.

      Contact Us

      If you have any questions or suggestions about the Terms and Conditions, please contact the Service Provider at landenfogle@gmail.com.
    `,
  },
]

const LandingPageLegal = () => {
  return (
    <div className="bg-white">
      <Header />
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Legal Information</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base  text-gray-600 ">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default LandingPageLegal;
