import { useState, useEffect } from "react"
import UsersList from "./UsersList"
import UserslistStudyHours from "./UsersListStudyHours"

  const EventMembers = (props) => {
    const [tabSelected, setTabSelected] = useState("Attendance");
    const [navigation, setNavigation] = useState([]);
  
    useEffect(() => {
      // Adjust navigation based on the event type
      if (props.Event.EventType === 'StudyHours') {
        setNavigation([
          { name: 'Attendance', current: true, members: props.Present?.length || 0 },
          { name: 'Absent', current: false, members: props.Absent?.length || 0 }
        ]);
      } else {
        setNavigation([
          { name: 'Attendance', current: true, members: props.Present?.length || 0 },
          { name: 'Absent', current: false, members: props.Absent?.length || 0 },
          { name: 'Exempt', current: false, members: props.Exempt?.length || 0 },
          { name: 'Semester Exempt', current: false, members: props.SemesterExempt?.length || 0 }
        ]);
      }
    }, [props.Event.EventType]);
  
    const tabSelect = (name) => {
      const updatedNavigation = navigation.map((item) => ({
        ...item,
        current: item.name === name,
      }));
      setNavigation(updatedNavigation);
      setTabSelected(name);
    }
  
    return (
      <div className="border-b border-gray-200 pb-0 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {navigation.map(tab => (
                <a
                  key={tab.name}
                  href="#"
                  className="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                  aria-current={tab.current ? 'page' : undefined}
                  style={{ borderColor: tab.current ? '#333' : 'transparent', color: tab.current ? '#333' : 'gray' }}
                  onClick={() => tabSelect(tab.name)}
                >
                  {tab.name} {tab.members > 0 && `(${tab.members})`}
                </a>
              ))}
            </nav>
          </div>
          {props.Event.EventType === 'StudyHours' ? (
            <>
              {tabSelected === 'Attendance' && <UserslistStudyHours Members={props.Present}/>}
              {tabSelected === 'Absent' && <UserslistStudyHours Members={props.Absent}/>}
            </>
          ) : (
            <>
              {tabSelected === 'Attendance' && <UsersList Members={props.Present} type={'Attendance'} />}
              {tabSelected === 'Absent' && <UsersList Members={props.Absent} type={'Absent'} />}
              {tabSelected === 'Exempt' && <UsersList Members={props.Exempt} type={'Exempt'} />}
              {tabSelected === 'Semester Exempt' && <UsersList Members={props.SemesterExempt} type={'Exempt'} />}
            </>
          )}
        </div>
      </div>
    );
  };
  
  export default EventMembers;