import { useState } from 'react';
import EventModal from './EventModal';

const LiveEvents = ({Events, user}) => {
  const [open, setOpen] = useState("");
  const [openAdd, setOpenAdd] = useState(false);

  return (
    <div>
      <h3 className="text-2xl font-semibold leading-6 text-gray-900 pt-5 pb-5">Live Events:</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {Events.map((event) => (
          <div key={event.title} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 flex">
            <div className="flex-1">
              <button onClick={() => setOpen(event.id)} className="w-full text-left focus:outline-none">
                <dl className="p-4">
                  <dt className="truncate text-md md:text-xl lg:text-2xl font-medium text-gray-500">{event.EventName}</dt>
                  <dd className="mt-1 text-lg md:text-xl lg:text-2xl font-semibold tracking-tight text-gray-900"></dd>
                  <div className="mt-1 flex items-center gap-x-2 text-xs md:text-sm leading-5 text-gray-500">
                    <p>{new Date(event.StartDate.seconds * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs md:text-sm leading-5 text-gray-500">
                    <p>{event.Moderator}</p>
                  </div>
                </dl>
              </button>
            </div>
            <div>
              {open === event.id && (
                <EventModal open={open} setOpen={setOpen} event={event} Organization={user.Organization} />
              )}
            </div>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default LiveEvents;
